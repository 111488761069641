<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="block has-text-centered">
                  <img class="image_main" src="@/assets/image/error.png" alt="Error">
                </div>
                <div class="block">
                  <h1 class="has-text-centered title_view">
                    ¡Error!
                  </h1>
                </div>
                <div class="block">
                  <p class="font_15 has-text-weight-semibold has_gray has-text-centered">
                    Página no encontrada
                  </p>
                </div>
                <div class="block has-text-centered">
                  <router-link class="button btn_violet" to="/">
                    Inicio
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>

<style lang="scss" scoped>
  .card {
    padding: 10rem 100px;
  }
  .image_main {
    width: 170px;
  }
  .button {
    width: 170px;
  }
</style>
